export const TITLE = {
	title: ['наш колектив'],
	imgPath: 'image/history/picture-title.png'
};

export const ARTICLES_ADMINISTRATION = [
	[
		{
			image: 'image/team/administration/Maria Polishchuk.jpg',
			name: 'Поліщук Марія',
			middleName: 'Богданівна',
			text: `Директор, кандидат технічних наук, спеціаліст вищої категорії, педагогічне звання «викладач-методист»`
		},
		{
			image: 'image/team/administration/picture-2.png',
			name: 'Хиль Сергій',
			middleName: 'Володимирович',
			text: `заступник директора з навчально-виробничої роботи,викладач математики, спеціаліст другої категорії`
		},
		{
			image: 'image/team/administration/picture-6.png',
			name: 'Химко Леся',
			middleName: 'Ярославівна',
			text: `заступник директора з навчально-виховної роботи. Bикладач історії, спеціаліст вищої категорії, педагогічне звання «старший викладач»`
		},
		{
			image: 'image/team/administration/picture-4.png',
			name: 'Біленький Петро',
			middleName: 'Миронович',
			text: `старший майстер, спеціаліст вищої категорії, педагогічне звання «викладач-методист»`
		},
			
		{
			image: 'image/team/administration/picture-7.png',
			name: 'Закалюжний Ростислав',
			middleName: 'Євстахійович',
			text: `керівник фізичного виховання та спорту, викладач фізичної культури, тренер з фехтування,
				спеціаліст вищої категорії, педагогічне звання «старший викладач»`
		},
		{
			image: 'image/team/administration/picture-8.png',
			name: 'Трущак Ольга',
			middleName: 'Василівна',
			text: `методист, викладач англійської мови, спеціаліст вищої категорії`
		}
	]
];

export const ARTICLES_TEACHERS = [
	[
		{
			image: 'image/team/teachers/BOM.png',
			name: 'Білецька Олена',
			middleName: 'Михайлівна',
			text: `викладач комп’ютерних дисциплін та інформатики, спеціаліст вищої 
				категорії, педагогічне звання «старший викладач»`
		},
		{
			image: 'image/team/administration/picture-3.png',
			name: 'Дика Леся',
			middleName: 'Євгенівна',
			text: `викладач української мови та літератури, спеціаліст вищої категорії,	педзвання "старший викладач"`
		},
		{
			image: 'image/team/masters/19.jpg',
			name: 'Росіцька Оксана',
			middleName: 'Василівна',
			text: 'викладач комп’ютерних технологій, спеціаліст другої категорії'
		},
		{
			image: 'image/team/teachers/BPP.png',
			name: 'Берник Петро',
			middleName: 'Петрович',
			text: `викладач предмета «Захист України», спеціаліст першої категорії`
		},
		{
			image: 'image/team/teachers/BOI.png',
			name: 'Бакун Оксана',
			middleName: 'Іванівна',
			text: `викладач англійської мови, спеціаліст вищої категорії`
		},
		{
			image: 'image/team/teachers/BVI.png',
			name: 'Бабаченко Віра',
			middleName: 'Іванівна',
			text: `викладач математики, спеціаліст вищої категорії`
		},
		{
			image: 'image/team/teachers/VGM.png',
			name: 'Варлам Ганна',
			middleName: 'Михайлівна',
			text: `викладач інформатики та програмування, спеціаліст вищої категорії`
		},
		{
			image: 'image/team/teachers/VOM.png',
			name: 'Владига Ольга',
			middleName: 'Миколаївна',
			text: `кандидат історичних наук, викладач історії, філософії та соціології, спеціаліст вищої
				категорії, педагогічне звання «старший викладач»`
		},
		{
			image: 'image/team/teachers/GDP.png',
			name: 'Гамаль Дарія',
			middleName: 'Петрівна',
			text: `викладач математики, спеціаліст вищої категорії, педагогічне звання «старший викладач»`
		},
		{
			image: 'image/team/teachers/GIV-2.png',
			name: 'Гайслер Ірина',
			middleName: 'Василівна',
			text: `викладач біології, хімії та екології, спеціаліст вищої категорії, педагогічне звання «старший викладач»`
		},
		{
			image: 'image/team/teachers/DNR-2.png',
			name: 'Друк Назар',
			middleName: 'Романович',
			text: `викладач предметів професійно-теоретичної підготовки професій автомобільного
				напряму, спеціаліст вищої категорії`
		},
		
		{
			image: 'image/team/teachers/KOS-2.png',
			name: 'Кубай Орест',
			middleName: 'Степанович',
			text: `викладач предметів професійно-теоретичної підготовки професій автомобільного напряму,
				спеціаліст вищої категорії, педагогічне звання «старший викладач»`
		},
		{
			image: 'image/team/teachers/GMM-2.png',
			name: 'Жукевич Марія',
			middleName: 'Михайлівна',
			text: `викладач креслення, образотворчого мистецтва та предметів професійно- теоретичної підготовки будівельного напряму,
				спеціаліст вищої категорії, педагогічне звання «старший викладач»`
		},
		{
			image: 'image/team/teachers/KTI-2.png',
			name: 'Корзан Тетяна',
			middleName: 'Ігорівна',
			text: `кандидат наук з фізичного виховання і спорту, майстер спорту, викладач фізичної культури,
				спеціаліст вищої категорії, педагогічне звання «старший викладач»`
		},
		{
			image: 'image/team/teachers/ILV-2.png',
			name: 'Ільків Любов',
			middleName: 'Василівна',
			text: `викладач предметів професійно-теоретичної підготовки професій будівельного напряму,
				спеціаліст вищої категорії, педагогічне звання «старший викладач»`
		},
		{
			image: 'image/team/teachers/LYV-3.png',
			name: 'Лучишин Надія',
			middleName: 'Василівна',
			text: `викладач предметів професійно-теоретичної підготовки професій будівельного напряму,
				спеціаліст вищої категорії, педагогічне звання «старший викладач»`
		},
		{
			image: 'image/team/teachers/MMA-3.png',
			name: 'Маслій Марія',
			middleName: 'Антонівна',
			text: `викладач фізики й астрономії, спеціаліст першої категорії,
				педагогічне звання «старший викладач»`
		},
		{
			image: 'image/team/teachers/PAY-3.png',
			name: 'Пастернак Андрій',
			middleName: 'Ярославович',
			text: `викладач предметів професійно-теоретичної підготовки професій автомобільного напряму,
				спеціаліст вищої категорії`
		},
		{
			image: 'image/team/teachers/POY-3.png',
			name: 'Погарата Ольга',
			middleName: 'Ярославівна',
			text: `викладач економіки, спеціаліст першої категорії`
		},
		{
			image: 'image/team/teachers/w-empty.png',
			name: 'Романишин Наталя',
			middleName: 'Сергіївна',
			text: `викладач хімії та біології спеціаліст першої категорії`
		},
		{
			image: 'image/team/teachers/SGV-4.png',
			name: 'Скородзієвська Галина',
			middleName: 'Василівна',
			text: `викладач англійської мови, спеціаліст першої категорії`
		},
		{
			image: 'image/team/teachers/SLM-4.png',
			name: 'Шафранська Любов',
			middleName: 'Михайлівна',
			text: `викладач зарубіжної літератури, спеціаліст вищої категорії, педагогічне звання «старший викладач»`
		},
		{
			image: 'image/team/teachers/w-empty.png',
			name: 'Сулипа Юрій',
			middleName: 'Ярославович',
			text: `викладач спецпредметів`
		},
		{
			image: 'image/team/teachers/w-empty.png',
			name: 'Закалюжний Назарій',
			middleName: 'Ростиславович',
			text: `викладач фізичного виховання`
		},
		{
			image: 'image/team/teachers/w-empty.png',
			name: 'Курій Тарас',
			middleName: 'Іванович',
			text: `викладач історії`
		}
	]
];

export const ARTICLES_MASTERS = [
	[
		{
			image: 'image/team/masters/1.jpg',
			name: 'Богдан Галина',
			middleName: 'Василівна',
			text: "майстер виробничого навчання з професії «Оператор з обробки інформації та програмного забезпечення; електромеханік з ремонту лічильно-обчислювальних машин»"
		},
		{
			image: 'image/team/masters/2.jpg',
			name: 'Бажило Роман',
			middleName: 'Володимирович',
			text: "майстер виробничого навчання з  професії «Слюсар з ремонту колісних транспортних засобів; електрогазозварник»"
		},
		{
			image: 'image/team/masters/3.jpg',
			name: 'Вишневська Тетяна',
			middleName: 'Іванівна',
			text: `майстер виробничого навчання з професії «Штукатур; лицювальник-плиточник», педагогічне звання «майстер виробничого
				навчання другої категорії»`
		},
		{
			image: 'image/team/masters/w-empty.png',
			name: 'Гадомська Любов',
			middleName: 'Йосифівна',
			text: "майстер виробничого навчання з  професії «Штукатур; маляр; лицювальник-плиточник»"
		},
		{
			image: 'image/team/masters/5.jpg',
			name: 'Грет Роман Ігорович',
			middleName: 'Ігорович',
			text: "майстер виробничого навчання з професії «Штукатур; лицювальник-плиточник»"
		},
		{
			image: 'image/team/masters/7.jpg',
			name: 'Гудима Наталія',
			middleName: 'Василівна',
			text: "майстер виробничого навчання з  професії «Оператор з обробки інформації та програмного забезпечення; електромеханік з ремонту лічильно-обчислювальних машин"
		},
		{
			image: 'image/team/masters/8.jpg',
			name: 'Дмуховська Уляна',
			middleName: 'Михайлівна',
			text: `майстер виробничого навчання з професії «Штукатур; маляр; лицювальник-плиточник»,
				педагогічне звання «майстер виробничого навчання другої категорії»`
		},
		{
			image: 'image/team/masters/w-empty.png',
			name: 'Демчина Мирослава',
			middleName: 'Ігорівна',
			text: "майстер виробничого навчання з професії «Оператор з обробки інформації та програмного забезпечення; електромеханік з ремонту лічильно-обчислювальних машин»"
		},
		{
			image: 'image/team/masters/11.jpg',
			name: 'Кандиба Світлана',
			middleName: 'Юліанівна',
			text: "майстер виробничого навчання з  професії «Штукатур; маляр; лицювальник-плиточник», педагогічне звання «майстер виробничого навчання другої категорії»"
		},
		{
			image: 'image/team/masters/22.jpg',
			name: 'Виборов Іван',
			middleName: 'Сергійович',
			text: "майстер виробничого навчання  з професії професії «Слюсар з ремонту колісних транспортних засобів; електрогазозварник»"
		},
		{
			image: 'image/team/masters/13.jpg',
			name: 'Мілян Христина',
			middleName: 'Степанівна',
			text: "майстер виробничого навчання з  професії «Оператор з обробки інформації та програмного забезпечення; електромеханік з ремонту лічильно-обчислювальних машин»"
		},
		{
			image: 'image/team/masters/14.jpg',
			name: 'Марценюк Марія',
			middleName: 'Павлівна',
			text: "майстер виробничого навчання з професії «Оператор з обробки інформації та програмного забезпечення; електромеханік з ремонту лічильно-обчислювальних машин»"
		},
				{
			image: 'image/team/masters/16.jpg',
			name: 'Новосядлий Михайло',
			middleName: 'Генрикович',
			text: "майстер виробничого навчання з професії «Слюсар з ремонту колісних транспортних засобів; електрогазозварник»"
		},
				
				{
			image: 'image/team/masters/21.jpg',
			name: 'Якимів Людмила',
			middleName: 'Михайлівна',
			text: "майстер виробничого навчання з професії «Оператор з обробки інформації та програмного забезпечення; електромеханік з ремонту лічильно-обчислювальних машин»"
		},
		{
			image: 'image/team/masters/w-empty.png',
			name: 'Лучишин Назар',
			middleName: 'Русланович',
			text: "майстер виробничого навчання професій будівельного напрямку"
		},
		{
			image: 'image/team/masters/w-empty.png',
			name: 'Корзан Ростислав',
			middleName: 'Ростиславович',
			text: "майстер виробничого навчання з професії «Електрогазозварник»"
		},
		{
			image: 'image/team/masters/w-empty.png',
			name: 'Карпінський Олег',
			middleName: 'Юліанович',
			text: "майстер виробничого навчання професій автомобільного напрямку"
		},
		{
			image: 'image/team/masters/w-empty.png',
			name: 'Лящишин Богдан',
			middleName: 'Степанович',
			text: "майстер виробничого навчання професій комп'ютерного напрямку"
		},
		{
			image: 'image/team/masters/w-empty.png',
			name: 'Глова Андрій',
			middleName: 'Миколайович',
			text: "майстер виробничого навчання професій автомобільного напрямку"
		},
		{
			image: 'image/team/masters/w-empty.png',
			name: 'Сорочинський Олександр',
			middleName: 'Миколайович',
			text: "майстер виробничого навчання професій автомобільного напрямку"
		},
		{
			image: 'image/team/masters/Ilechko.jpeg',
			name: 'Ілечко Ігор',
			middleName: 'Богданович',
			text: "майстер виробничого навчання професій автомобільного напрямку"
		},
	]
];

export const ARTICLES_PROPS = [
	{
		administration: 'адміністрація',
		teachers: 'викладачі',
		masters: 'майстри виробничого навчання'
	}
];